import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#FFFFFF" width={200} height={200} />
      <path fill="#F79A3E" d="M47.9,20.8c0.1,0,0.1,0,0.2,0.1C66.9,39.7,67,70.2,48.1,89.1L13.8,54.8L47.9,20.8z" />
      <path fill="#00363D" d="M86.9,59.8c0-0.1,0-0.1-0.1-0.2c-13.4-13.4-13.4-35.2,0-48.6l24.4,24.4L86.9,59.8z" />
      <path
        fill="#00363D"
        d="M102.1,139.7c1.8,0,3.5-0.6,4.7-1.8l2.3,2.5c-1.6,1.6-3.7,2.8-7,2.8c-5.7,0-9.4-3.8-9.4-8.9 c-0.1-4.8,3.8-8.8,8.6-8.9c0.1,0,0.2,0,0.3,0c5.7,0,8.9,4.3,8.6,10.3H96.8C97.3,138.1,99.1,139.6,102.1,139.7 M106.2,132.7 c-0.3-2.3-1.7-4-4.5-4c-2.6,0-4.4,1.5-4.8,4H106.2z"
      />
      <polygon
        fill="#00363D"
        points="13.8,139.5 24.2,129 14.1,129 14.1,125.7 29,125.7 29,129 18.6,139.5 29.1,139.5 29.1,142.8  13.8,142.8 "
      />
      <path
        fill="#00363D"
        d="M40.7,139.7c1.8,0,3.5-0.6,4.7-1.8l2.3,2.5c-1.6,1.6-3.7,2.8-7,2.8c-5.7,0-9.4-3.8-9.4-8.9 c-0.1-4.8,3.8-8.8,8.6-8.9c0.1,0,0.2,0,0.3,0c5.7,0,8.9,4.3,8.6,10.3H35.4C35.9,138,37.7,139.6,40.7,139.7 M44.8,132.7 c-0.3-2.3-1.7-4-4.5-4c-2.6,0-4.4,1.5-4.8,4H44.8z"
      />
      <path
        fill="#00363D"
        d="M71.2,134.2c0-5.5,4.1-8.9,8.6-8.9c2.3,0,4.5,1,5.7,2.6v-10.1h3.7v25.1h-3.6v-2.4c-1.4,1.7-3.5,2.7-5.8,2.7 C75.4,143.1,71.2,139.7,71.2,134.2 M85.8,134.2c0-3.1-2.3-5.6-5.5-5.6c-3,0-5.4,2.4-5.4,5.4c0,0,0,0.1,0,0.1c0,3.1,2.4,5.6,5.5,5.6 C83.5,139.7,85.8,137.2,85.8,134.2"
      />
      <path
        fill="#00363D"
        d="M112.7,139.1l3.3-1.7c0.9,1.6,2.6,2.6,4.4,2.5c2.1,0,3.1-1.1,3.1-2.3c0-1.4-2-1.7-4.2-2.1 c-2.9-0.6-6-1.6-6-5.1c0-2.7,2.6-5.2,6.7-5.2c3.2,0,5.6,1.3,6.9,3.3l-3.1,1.7c-0.9-1.3-2.3-2-3.9-2c-2,0-3,1-3,2.1 c0,1.2,1.6,1.6,4.1,2.1c2.8,0.6,6,1.5,6,5.1c0,2.4-2.1,5.6-7,5.5C116.6,143.1,114.1,141.7,112.7,139.1"
      />
      <polygon
        fill="#00363D"
        points="137.1,135.1 134.2,138.3 134.2,142.8 130.6,142.8 130.6,117.7 134.2,117.7 134.2,134.1 142,125.6  146.4,125.6 139.7,133 146.6,142.8 142.5,142.8 "
      />
      <path
        fill="#00363D"
        d="M60.2,125.3c-4.3,0-8,2.8-8,7.5v10h3.7v-9.6c0-2.8,1.6-4.5,4.4-4.5s4.1,1.7,4.1,4.5v9.6h3.7v-10 C68.2,128.1,64.5,125.3,60.2,125.3"
      />
      <path
        fill="#00363D"
        d="M13.8,166.5c-0.1-4.9,3.9-9,8.8-9c0.1,0,0.2,0,0.2,0c3.9,0,6.9,2.2,8.2,5.3l-3.3,1.4c-0.8-2-2.7-3.3-4.9-3.3 c-3.1,0-5.4,2.5-5.4,5.6c0,3,2.4,5.5,5.4,5.6c0,0,0.1,0,0.1,0c2.4,0,4.1-1.5,4.9-3.4l3.4,1.4c-1.4,3.4-4.7,5.6-8.3,5.5 c-4.9,0.1-9-3.9-9.1-8.8C13.8,166.6,13.8,166.6,13.8,166.5z"
      />
      <path
        fill="#00363D"
        d="M34.2,149.8h3.7V160c1.3-1.6,3.3-2.6,5.4-2.5c4.4,0,7.1,2.8,7.1,7.6v10.2h-3.8v-9.8c0-2.9-1.4-4.7-4.2-4.7 c-2.5,0-4.4,1.9-4.4,4.9v9.6h-3.7V149.8z"
      />
      <path
        fill="#00363D"
        d="M53.4,166.5c0-5.6,4.1-9,8.7-9c2.2,0,4.3,0.9,5.8,2.6v-2.3h3.7v17.4h-3.7v-2.4c-1.4,1.8-3.6,2.8-5.8,2.7 C57.6,175.5,53.4,172,53.4,166.5z M68.1,166.5c0-3.1-2.3-5.6-5.5-5.6c-3.1,0-5.5,2.5-5.5,5.6c0,3.2,2.4,5.7,5.5,5.7 C65.7,172.1,68.1,169.6,68.1,166.5L68.1,166.5z"
      />
      <path
        fill="#00363D"
        d="M74.2,157.8h3v-4.1l3.8-2.1v6.2h3.7v3.3h-3.7v6.9c0,3.1,0.5,3.6,3.7,3.6v3.5H84c-5.3,0-6.9-1.7-6.9-7.1v-7 h-3L74.2,157.8z"
      />
    </svg>
  )
}

export default Svg

import * as React from "react"

function Svg(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="250" height="250" fill="white" />
      <path
        d="M81.4949 91.7565V100.084C81.4949 104.68 79.2977 107.179 75.2497 107.179C71.2047 107.179 69.127 104.56 69.127 100.029V91.7565H67.2637V89.5837H73.5601V91.7565H71.7019V100.282C71.7019 103.3 72.9248 104.913 75.3002 104.913C77.7483 104.913 78.9482 103.342 78.9482 100.451V91.7565H77.0819V89.5837H83.3635V91.7565H81.4949Z"
        fill="#112E50"
      />
      <path
        d="M91.4582 106.91V104.853H92.9498V98.9405C92.9498 96.4603 92.1647 95.7538 90.8483 95.7538C89.6201 95.7538 88.7816 96.3965 88.0551 97.352V104.853H89.5652V106.91H84.0391V104.853H85.5276V95.972H84.0391V93.9155H88.0551V95.2513C88.881 94.262 89.9422 93.6433 91.476 93.6433C94.0599 93.6433 95.4742 95.0945 95.4742 98.7628V104.853H96.9955V106.91H91.4582Z"
        fill="#112E50"
      />
      <path
        d="M98.4355 106.91V104.853H100.022V95.9719H98.4355V93.9154H102.552V104.853H104.138V106.91H98.4355ZM101.032 91.7382C100.121 91.7382 99.3839 90.9919 99.3839 90.1039C99.3839 89.1837 100.121 88.4397 101.032 88.4397C101.914 88.4397 102.646 89.1837 102.646 90.1039C102.646 90.9919 101.914 91.7382 101.032 91.7382Z"
        fill="#112E50"
      />
      <path
        d="M112.024 106.591C111.432 106.932 110.6 107.179 109.645 107.179C107.955 107.179 106.883 106.064 106.883 103.736V95.9721H105.143V93.9156H106.883V90.2009L109.404 88.8584V93.9156H112.295V95.9721H109.404V103.498C109.404 104.71 109.72 105.054 110.6 105.054C110.865 105.054 111.13 105.012 111.377 104.941L112.024 106.591Z"
        fill="#112E50"
      />
      <path
        d="M118.73 95.7538C117.06 95.7538 116.201 96.9846 116.026 99.2826H121.495C121.353 97.3521 120.687 95.7538 118.73 95.7538ZM123.951 101.313H116.011C116.201 103.669 117.176 105.001 119.193 105.001C120.642 105.001 121.495 104.61 122.642 103.737L123.722 105.478C122.332 106.635 120.905 107.179 119.045 107.179C115.888 107.179 113.535 104.73 113.535 100.427C113.535 96.0171 115.715 93.6433 118.878 93.6433C122.358 93.6433 124.019 96.6426 124.019 100.155C124.019 100.623 123.967 101.039 123.951 101.313Z"
        fill="#112E50"
      />
      <path
        d="M133.788 97.4529C133.247 96.6827 132.171 95.7534 130.969 95.7534C129.28 95.7534 128.391 97.2339 128.391 100.124C128.391 103.736 129.392 105.083 131.092 105.083C132.123 105.083 132.809 104.585 133.788 103.421V97.4529ZM133.762 106.91V105.545C132.887 106.592 131.95 107.18 130.575 107.18C127.833 107.18 125.875 105.152 125.875 100.207C125.875 95.7534 128.026 93.6429 130.455 93.6429C132.001 93.6429 132.948 94.3127 133.762 95.1782V90.6114H132.237V88.5647H136.265V104.853H137.629V106.91H133.762Z"
        fill="#112E50"
      />
      <path
        d="M151.584 107.179C149.92 107.179 148.694 106.513 147.859 105.502V106.91H145.652V101.611H147.74C148.35 103.736 149.427 104.961 151.536 104.961C153.414 104.961 154.428 104.044 154.428 102.357C154.428 100.843 153.959 100.084 150.844 99.1874C147.438 98.2244 145.777 97.1084 145.777 94.0132C145.777 91.086 147.755 89.3062 150.678 89.3062C152.635 89.3062 153.735 90.1035 154.276 90.9922V89.5837H156.481V94.8794H154.398C154.081 92.8462 152.953 91.5337 150.844 91.5337C149.03 91.5337 148.373 92.4764 148.373 93.8355C148.373 95.271 149.304 96.0697 151.831 96.7814C155.259 97.7752 157.028 98.97 157.028 102.063C157.028 105.478 154.789 107.179 151.584 107.179Z"
        fill="#112E50"
      />
      <path
        d="M165.322 106.591C164.73 106.932 163.901 107.179 162.942 107.179C161.256 107.179 160.184 106.064 160.184 103.736V95.972H158.441V93.9155H160.184V90.2007L162.702 88.8582V93.9155H165.593V95.972H162.702V103.497C162.702 104.709 163.018 105.054 163.901 105.054C164.166 105.054 164.428 105.012 164.675 104.941L165.322 106.591Z"
        fill="#112E50"
      />
      <path
        d="M174.473 101.193C173.834 100.873 173.13 100.623 171.633 100.623C170.241 100.623 169.277 101.421 169.277 102.781C169.277 104.365 169.994 105.054 171.461 105.054C172.688 105.054 173.771 104.365 174.473 103.422V101.193ZM174.473 106.91V105.501C173.691 106.513 172.515 107.179 170.999 107.179C168.782 107.179 166.883 106.04 166.883 102.855C166.883 99.9553 168.91 98.5911 171.633 98.5911C172.859 98.5911 173.771 98.8385 174.473 99.212V98C174.473 96.5863 173.639 95.8003 172.144 95.8003C170.899 95.8003 169.914 96.0486 168.961 96.6133L168.034 94.8058C169.212 94.0423 170.574 93.6433 172.297 93.6433C175.011 93.6433 176.924 95.0488 176.924 98V104.853H178.322V106.91H174.473Z"
        fill="#112E50"
      />
      <path
        d="M185.832 106.591C185.24 106.932 184.41 107.179 183.453 107.179C181.764 107.179 180.694 106.064 180.694 103.736V95.972H178.951V93.9155H180.694V90.2007L183.212 88.8582V93.9155H186.102V95.972H183.212V103.497C183.212 104.709 183.53 105.054 184.41 105.054C184.676 105.054 184.939 105.012 185.186 104.941L185.832 106.591Z"
        fill="#112E50"
      />
      <path
        d="M192.566 95.7538C190.896 95.7538 190.044 96.9846 189.87 99.2826H195.328C195.195 97.3521 194.518 95.7538 192.566 95.7538ZM197.784 101.313H189.841C190.044 103.669 191.024 105.001 193.023 105.001C194.479 105.001 195.328 104.61 196.483 103.737L197.567 105.478C196.168 106.635 194.741 107.179 192.881 107.179C189.726 107.179 187.371 104.73 187.371 100.427C187.371 96.0171 189.552 93.6433 192.708 93.6433C196.192 93.6433 197.856 96.6426 197.856 100.155C197.856 100.623 197.804 101.039 197.784 101.313Z"
        fill="#112E50"
      />
      <path
        d="M204.907 107.099C203.417 107.099 202.671 106.662 201.993 105.868V106.91H200.028V102.855H201.769C202.548 104.339 203.338 105.083 204.807 105.083C205.862 105.083 206.424 104.46 206.424 103.566C206.424 102.607 206.01 102.063 204.198 101.543C201.064 100.623 200.004 99.6761 200.004 97.3301C200.004 95.4454 201.354 93.7129 203.926 93.7129C205.074 93.7129 205.932 93.9911 206.623 94.7036V93.9154H208.581V97.9781H206.835C206.26 96.4916 205.467 95.6966 204.069 95.6966C203 95.6966 202.504 96.2621 202.504 97.1344C202.504 97.9219 202.799 98.4926 204.732 99.0896C207.747 100.029 208.919 100.917 208.919 103.377C208.919 105.429 207.379 107.099 204.907 107.099Z"
        fill="#112E50"
      />
      <path
        d="M59.2266 110.083C56.9062 108.457 54.4522 107.646 51.8728 107.646C48.7591 107.646 45.707 109.083 44.0551 111.395C42.266 113.891 41.4676 117.514 41.4676 122.576C41.4676 128.507 41.9966 131.818 43.3888 134.192C45.2461 137.378 48.2916 139.064 52.2676 139.064C54.7846 139.064 56.9752 138.375 59.2266 136.939C59.4908 136.752 59.8247 136.504 60.1557 136.252L63.8734 142.139C60.3753 144.675 56.5945 145.81 51.6064 145.81C45.2462 145.81 40.3396 143.873 36.5685 139.812C32.8581 135.878 31 130.63 31 124.259C31 118.826 31.9922 114.515 34.1145 110.771C37.6912 104.461 44.3156 100.715 51.7414 100.715C55.9682 100.715 60.1958 101.888 62.7974 103.697L59.2266 110.083Z"
        fill="#112E50"
      />
      <path
        d="M74.6552 125.377H84.1343V125.007C84.1343 122.661 83.8902 121.423 83.1585 120.248C82.363 119.013 81.2017 118.393 79.5469 118.393C76.428 118.393 74.6552 120.867 74.6552 125.257V125.377ZM89.0891 136.197L92.2695 141.143C88.6616 144.111 84.8704 145.532 80.467 145.532C71.4739 145.532 65.6621 139.103 65.6621 129.148C65.6621 123.462 66.8264 119.691 69.5787 116.6C72.1478 113.695 75.2667 112.335 79.4281 112.335C83.0383 112.335 86.4614 113.572 88.4791 115.674C91.356 118.643 92.6354 122.906 92.6354 129.521V131.436H74.532V131.683C74.532 136.568 76.9177 139.349 81.1393 139.349C83.951 139.349 86.5868 138.299 89.0891 136.197Z"
        fill="#112E50"
      />
      <path
        d="M104.689 112.63L104.682 116.106C106.576 114.748 106.944 114.5 108.29 113.758C109.938 112.891 112.082 112.398 113.918 112.398C117.406 112.398 120.46 114.252 121.438 116.973C121.867 118.146 122.051 119.507 122.051 121.487V144.604H113.978V124.02C113.978 120.433 113.367 119.383 111.225 119.383C109.574 119.383 107.433 120.496 105.539 122.227V144.604H97.3398V112.63H104.689Z"
        fill="#112E50"
      />
      <path
        d="M147.611 120.619C144.488 119.012 142.228 118.333 139.904 118.333C137.515 118.333 135.928 119.57 135.928 121.423C135.928 123.029 136.967 123.896 139.842 124.64L143.635 125.628C147.487 126.615 148.77 127.791 149.869 129.212C151.034 130.696 151.582 132.488 151.582 134.591C151.582 141.143 146.202 145.594 138.191 145.594C134.437 145.594 130.33 144.418 125.877 142.128L128.464 135.889C130.973 137.434 135.315 139.474 138.859 139.474C141.188 139.474 143.023 137.929 143.023 135.889C143.023 133.724 141.493 132.612 138.191 131.995L134.522 131.314C132.44 130.941 129.873 129.458 128.77 128.098C127.669 126.738 126.997 124.453 126.997 122.413C126.997 116.23 131.832 112.088 139.106 112.088C143.842 112.088 147.047 113.464 149.809 114.813L147.611 120.619Z"
        fill="#112E50"
      />
      <path
        d="M173.385 144.599L173.366 141.127C171.47 142.485 171.105 142.731 169.76 143.473C168.108 144.338 165.966 144.833 164.13 144.833C160.64 144.833 157.584 142.981 156.606 140.257C156.178 139.086 155.998 137.725 155.998 135.747V112.626H164.069V133.21C164.069 136.799 164.679 137.849 166.825 137.849C168.473 137.849 170.614 136.735 172.512 135.004V112.626H180.707V144.599H173.385Z"
        fill="#112E50"
      />
      <path
        d="M206.331 120.619C203.209 119.012 200.946 118.333 198.621 118.333C196.233 118.333 194.644 119.57 194.644 121.423C194.644 123.029 195.684 123.896 198.561 124.64L202.355 125.628C206.206 126.615 207.491 127.791 208.589 129.212C209.751 130.696 210.299 132.488 210.299 134.591C210.299 141.143 204.922 145.594 196.909 145.594C193.152 145.594 189.048 144.418 184.596 142.128L187.183 135.889C189.692 137.434 194.033 139.474 197.581 139.474C199.906 139.474 201.742 137.929 201.742 135.889C201.742 133.724 200.212 132.612 196.909 131.995L193.237 131.314C191.16 130.941 188.592 129.458 187.488 128.098C186.389 126.738 185.716 124.453 185.716 122.413C185.716 116.23 190.549 112.088 197.827 112.088C202.56 112.088 205.765 113.464 208.525 114.813L206.331 120.619Z"
        fill="#112E50"
      />
      <path
        d="M159.439 156.683V160.126H161.608C162.135 160.126 162.497 160.033 162.785 159.834C163.166 159.541 163.438 158.956 163.438 158.353C163.438 157.618 163.056 156.997 162.514 156.796C162.207 156.703 162.118 156.683 161.534 156.683H159.439ZM159.402 151.651V154.579H161.427C162.023 154.579 162.299 154.545 162.514 154.416C162.858 154.232 163.166 153.61 163.166 153.097C163.166 152.566 162.875 152.037 162.443 151.816C162.225 151.726 161.954 151.651 161.39 151.651H159.402ZM156.902 149.473H161.681C163.221 149.473 164.015 149.71 164.778 150.388C165.487 151.009 165.808 151.744 165.808 152.66C165.808 153.517 165.504 154.251 164.959 154.8C164.598 155.148 164.38 155.275 163.8 155.459C165.487 155.971 166.299 156.997 166.299 158.662C166.299 160.42 165.212 162.031 163.002 162.178C162.605 162.195 162.077 162.213 161.39 162.213H156.902V149.473Z"
        fill="#112E50"
      />
      <path
        d="M173.164 162.209V161.555C172.567 162.14 171.696 162.451 170.721 162.451C169.416 162.451 168.293 161.83 167.987 160.932C167.841 160.51 167.785 160.018 167.785 159.066V153.06H170.175V158.627C170.175 159.467 170.231 159.908 170.376 160.163C170.503 160.42 170.867 160.602 171.228 160.602C171.824 160.602 172.55 160.163 172.73 159.704V153.06H175.05V162.209H173.164Z"
        fill="#112E50"
      />
      <path
        d="M179.272 153.06V153.958C179.619 153.481 180.034 153.08 180.487 152.859C180.687 152.767 181.014 152.713 181.286 152.713C181.647 152.713 181.809 152.75 182.192 152.915L181.683 154.984C181.446 154.854 181.248 154.8 180.974 154.8C180.431 154.8 179.943 155.056 179.488 155.587V162.213H177.062V153.06H179.272Z"
        fill="#112E50"
      />
      <path
        d="M185.252 156.52H188.059V156.409C188.059 155.715 187.987 155.349 187.769 155.003C187.535 154.634 187.188 154.451 186.702 154.451C185.778 154.451 185.252 155.186 185.252 156.482V156.52ZM189.528 159.723L190.467 161.187C189.4 162.068 188.278 162.486 186.972 162.486C184.312 162.486 182.59 160.584 182.59 157.637C182.59 155.953 182.936 154.835 183.75 153.92C184.511 153.06 185.433 152.66 186.666 152.66C187.733 152.66 188.748 153.025 189.347 153.648C190.195 154.526 190.577 155.789 190.577 157.748V158.315H185.215V158.387C185.215 159.834 185.922 160.658 187.173 160.658C188.005 160.658 188.785 160.346 189.528 159.723Z"
        fill="#112E50"
      />
      <path
        d="M196.743 158.113H196.631C194.951 158.113 194.371 158.426 194.371 159.54C194.371 160.273 194.823 160.768 195.439 160.768C195.892 160.768 196.342 160.529 196.706 160.127L196.743 158.113ZM197.393 162.209V161.555C197.052 161.884 197.013 161.92 196.833 162.049C196.381 162.378 195.728 162.56 194.967 162.56C192.902 162.56 191.781 161.502 191.781 159.632C191.781 157.436 193.283 156.409 196.234 156.409C196.415 156.409 196.525 156.409 196.777 156.428V156.043C196.777 155.003 196.579 154.654 195.693 154.654C194.915 154.654 194.009 155.037 193.012 155.715L191.98 153.958C192.577 153.572 192.831 153.446 193.481 153.189C194.387 152.804 195.169 152.64 196.018 152.64C197.575 152.64 198.645 153.224 199.007 154.268C199.133 154.654 199.171 154.947 199.171 155.953L199.117 159.101V159.266V162.213L197.393 162.209Z"
        fill="#112E50"
      />
      <path
        d="M206.347 162.209V161.555C205.748 162.14 204.88 162.451 203.9 162.451C202.596 162.451 201.476 161.83 201.167 160.932C201.023 160.51 200.965 160.018 200.965 159.066V153.06H203.358V158.627C203.358 159.467 203.413 159.908 203.557 160.163C203.687 160.42 204.047 160.602 204.409 160.602C205.006 160.602 205.729 160.163 205.91 159.704V153.06H208.229V162.209H206.347Z"
        fill="#112E50"
      />
      <path
        d="M35.7224 153.716H147.213C149.538 153.716 151.421 155.614 151.421 157.966C151.421 160.312 149.538 162.213 147.213 162.213H35.7224C33.402 162.213 31.5156 160.312 31.5156 157.966C31.5156 155.614 33.402 153.716 35.7224 153.716Z"
        fill="#112E50"
      />
      <path d="M212.492 95.1113V91.9411H211.32V91.5173H214.139V91.9411H212.962V95.1113H212.492Z" fill="#112E50" />
      <path
        d="M214.607 95.1113V91.5173H215.315L216.158 94.0628C216.235 94.2991 216.292 94.4761 216.327 94.5953C216.367 94.4633 216.43 94.2713 216.517 94.0178L217.368 91.5173H218.001V95.1113H217.548V92.1031L216.514 95.1113H216.089L215.06 92.0521V95.1113H214.607Z"
        fill="#112E50"
      />
    </svg>
  )
}

export default Svg

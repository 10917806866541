import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          fill="#00363D"
          d="M13.8,170.8l3.3-1.8c1,1.6,2.5,2.5,4.5,2.5c2.1,0,3.2-1,3.2-2.2c0-1.4-2.1-1.7-4.3-2.2 c-2.9-0.6-6-1.5-6-5.1c0-2.7,2.6-5.2,6.7-5.2c3.3,0,5.6,1.2,7,3.3l-3.1,1.7c-0.8-1.2-2.1-2-3.9-2c-2,0-3,0.9-3,2.1 c0,1.2,1.5,1.5,4.1,2.1c2.9,0.6,6.1,1.6,6.1,5.2c0,2.6-2.3,5.5-7.1,5.5C17.8,174.7,15.3,173.4,13.8,170.8z"
        />
        <path
          fill="#00363D"
          d="M31.4,167.2v-10h3.7v9.7c0,2.8,1.4,4.6,4.1,4.6c2.4,0,4.3-1.9,4.3-4.8v-9.4h3.7v17.2h-3.6v-2.2 c-1.2,1.6-3.2,2.6-5.2,2.6C33.9,174.7,31.4,171.9,31.4,167.2z"
        />
        <path
          fill="#00363D"
          d="M54.9,172.2v10h-3.6v-25.1h3.6v2.4c1.4-1.8,3.5-2.8,5.7-2.7c4.5,0,8.5,3.4,8.5,8.9c0,5.5-4.2,8.9-8.5,8.9 C58.3,174.7,56.1,173.8,54.9,172.2z M65.5,165.7c0-3.1-2.4-5.6-5.4-5.6c-3.1,0-5.4,2.5-5.4,5.6c0,3.1,2.3,5.6,5.4,5.6 C63.2,171.3,65.5,168.9,65.5,165.7z"
        />
        <path
          fill="#00363D"
          d="M76,172.2v10h-3.6v-25.1H76v2.4c1.4-1.8,3.5-2.8,5.7-2.7c4.5,0,8.5,3.4,8.5,8.9c0,5.5-4.2,8.9-8.5,8.9 C79.4,174.7,77.2,173.8,76,172.2z M86.6,165.7c0-3.1-2.4-5.6-5.4-5.6c-3.1,0-5.4,2.5-5.4,5.6c0,3.1,2.3,5.6,5.4,5.6 C84.3,171.3,86.6,168.9,86.6,165.7L86.6,165.7z"
        />
        <path
          fill="#00363D"
          d="M92.7,165.8c0-4.9,4-8.9,8.9-8.9c4.9,0,8.9,4,8.9,8.9c0,4.9-4,8.9-8.9,8.9c-4.9,0-8.9-3.9-8.9-8.8 C92.7,165.9,92.7,165.8,92.7,165.8z M106.8,165.8c0-3-2.3-5.5-5.3-5.5c-3,0-5.3,2.5-5.3,5.5s2.3,5.5,5.3,5.5 C104.5,171.3,106.8,168.8,106.8,165.8z"
        />
        <path
          fill="#00363D"
          d="M113.7,157.2h3.7v2.7c0.9-1.7,2.6-2.7,5-2.7h1.6v3.5h-2.2c-3.1,0-4.4,1.6-4.4,5.2v8.5h-3.7V157.2z"
        />
        <path
          fill="#00363D"
          d="M126.3,157.2h2.9v-4l3.7-2v6.1h3.6v3.3H133v6.8c0,3.1,0.5,3.6,3.6,3.6v3.5h-0.5c-5.2,0-6.8-1.6-6.8-7v-6.9 h-2.9L126.3,157.2z"
        />
        <rect
          x={5.1}
          y={32.3}
          transform="matrix(0.2721 -0.9623 0.9623 0.2721 -18.5804 75.4566)"
          fill="#78A300"
          width={71}
          height={35.5}
        />
        <rect
          x={71.4}
          y={49.3}
          transform="matrix(0.9623 -0.2721 0.2721 0.9623 -14.8949 26.7948)"
          fill="#00363D"
          width={35.5}
          height={35.5}
        />
        <path
          fill="#00363D"
          d="M102.1,139.6c1.8,0,3.5-0.6,4.7-1.8l2.3,2.5c-1.6,1.6-3.7,2.8-7,2.8c-5.7,0-9.4-3.8-9.4-8.9 c-0.1-4.8,3.8-8.8,8.6-8.9c0.1,0,0.2,0,0.3,0c5.7,0,8.9,4.3,8.6,10.3H96.8C97.3,138,99.1,139.6,102.1,139.6 M106.2,132.6 c-0.3-2.3-1.7-4-4.5-4c-2.6,0-4.4,1.5-4.8,4H106.2z"
        />
        <polygon
          fill="#00363D"
          points="13.8,139.4 24.2,128.9 14.1,128.9 14.1,125.6 29,125.6 29,128.9 18.6,139.4 29.1,139.4 29.1,142.7  13.8,142.7  "
        />
        <path
          fill="#00363D"
          d="M40.7,139.6c1.8,0,3.5-0.6,4.7-1.8l2.3,2.5c-1.6,1.6-3.7,2.8-7,2.8c-5.7,0-9.4-3.8-9.4-8.9 c-0.1-4.8,3.8-8.8,8.6-8.9c0.1,0,0.2,0,0.3,0c5.7,0,8.9,4.3,8.6,10.3H35.4C35.9,138,37.7,139.5,40.7,139.6 M44.8,132.6 c-0.3-2.3-1.7-4-4.5-4c-2.6,0-4.4,1.5-4.8,4H44.8z"
        />
        <path
          fill="#00363D"
          d="M71.2,134.1c0-5.5,4.1-8.9,8.6-8.9c2.3,0,4.5,1,5.7,2.6v-10.1h3.7v25.1h-3.6v-2.4c-1.4,1.7-3.5,2.7-5.8,2.7 C75.4,143,71.2,139.6,71.2,134.1 M85.8,134.1c0-3.1-2.3-5.6-5.5-5.6c-3,0-5.4,2.4-5.5,5.4c0,0,0,0.1,0,0.1c0,3.1,2.4,5.6,5.5,5.6 C83.5,139.7,85.8,137.1,85.8,134.1"
        />
        <path
          fill="#00363D"
          d="M112.7,139l3.3-1.7c0.9,1.6,2.6,2.6,4.4,2.5c2.1,0,3.1-1.1,3.1-2.3c0-1.4-2-1.7-4.2-2.1 c-2.9-0.6-6-1.6-6-5.1c0-2.7,2.6-5.2,6.7-5.2c3.2,0,5.6,1.3,6.9,3.3l-3.1,1.7c-0.9-1.3-2.3-2-3.9-1.9c-2,0-3,1-3,2.1 c0,1.2,1.6,1.6,4.1,2.1c2.8,0.6,6,1.5,6,5.1c0,2.4-2.1,5.6-7,5.5C116.6,143.1,114.1,141.6,112.7,139"
        />
        <polygon
          fill="#00363D"
          points="137.1,135.1 134.2,138.2 134.2,142.7 130.6,142.7 130.6,117.6 134.2,117.6 134.2,134 142,125.5  146.4,125.5 139.7,132.9 146.6,142.7 142.5,142.7  "
        />
        <path
          fill="#00363D"
          d="M60.2,125.2c-4.3,0-8,2.8-8,7.5v10h3.7v-9.6c0-2.8,1.6-4.5,4.4-4.5c2.8,0,4.1,1.7,4.1,4.5v9.6h3.7v-10 C68.2,128,64.5,125.2,60.2,125.2"
        />
      </g>
    </svg>
  )
}

export default Svg
